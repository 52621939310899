<template>
  <router-view/>
  <widget-container-modal />

</template>

<style lang="scss">
@import "styles/main";
</style>

<script>
import {container} from "jenesius-vue-modal";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {WidgetContainerModal: container},
  name: "App"
}
</script>
